  /* .user-form label {
    margin-bottom: 5px;
  }
  
  .user-form .error {
    color: red;
    margin-top: 5px;
  }
  
  .user-form .MuiFormControl-root {
    margin-bottom: 15px;
  }
   */
   .phoneinputcss input{
    height: 2.5375em;
    width: 100% ;
    /* border-color: #236C9C; */
    min-width: none;
    border: 1px solid #236C9C;
    border-radius: 4px;
   }
   .inputDivMargin{
    margin-bottom: 1rem !important;
   }
   .inputDivMargin  .MuiOutlinedInput-input{
    padding: 10px !important;
   }