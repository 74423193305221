.vertical-progress-bar {
    position: relative;
    width: 5px;
    height: 6rem;
    background-color: #f0f0f0;
    border-Radius: 5px
}

.fill {
    position: absolute;
    width: 100%;
    background-color: #286085;
    transition: height 0.5s ease-in-out;
}

.dashed {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #286085 50%, transparent 50%);
    background-size: 100% 20px;
}