.quote-card-container-main-div {
  background-color: var(--color-white);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: fit-content;
  min-height: 12rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px;
  display: flex;
}

.upper-container-div {
  width: 100%;
  height: 50%;
  /* background-color: bisque; */
  border-bottom: 2px solid #e7e7f0;

  /* display: flex; */
  /* justify-content: space-between; */
}

.lower-container-div {
  width: 100%;
  height: 50%;
  /* background-color: aqua; */
}
.company-heading {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 600;
  font-size: var(--fs-sublabel);
  color: var(--color-primary);
  margin: 0;
  padding-left: 1rem;
}
.heading-small {
  font-size: var(--fs-subtitle);
  font-weight: 500;
  /* color: var(--color-miscellaneous-2); */
  color: var(--color-primary);
  padding-left: 1.5rem;
  font-family: var(--family-label);
}
.data {
  color: var(--color-);
  font-size: var(--fs-subtitle);
  font-weight: 300;
  padding-left: 1.5rem;
  font-family: var(--family-label);
  color: var(--color-primary);

}

.schedule-button {
  border: 0.15rem solid var(--color-primary);
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  height: 2rem;
  font-size: var(--fs-subtitle);
  color: var(--color-white);
  padding: 5px 15px;
  border-radius: 22.2px;
}
