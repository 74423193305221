.btn-out-section {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  color: var(--color-primary-button);
  font-size: var(--fs-title);
  border: 1px solid var(--color-primary-button);
  background-color: transparent;
  border-radius: 50px;
}

.btn-filled-section {
  display: block;
  padding: 5px 20px;
  color: var(--color-white);
  font-size: var(--fs-small);
  border: none;
  background-color: var(--color-primary-button);
  border-radius: 50px;
}

.btnn-section {
  color: var(--color-primary-button);
  font-size: var(--fs-subtitle);
  border: none;

  background-color: none;
}

.btnn-icon {
  display: inline-flex;
  padding: 4px 12px;
  color: var(--color-white);
  font-size: var(--fs-subtitle);
  border: none;
  background-color: var(--color-primary-button);
  border-radius: 50px;
  align-items: center;
}
.rms-btn-text {
  font-size: var(--fs-label);
  font-weight: 700;
}
