/* *{
  font-family: "Poppins";
} */

.p-small {
  font-size: var(--fs-small);
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-head {
  font-size: var(--fs-mid);
}
.common-padding {
  padding: 2%;
}
.common-marginTop {
  margin-top: "10%";
}
.align-center {
  text-align: center;
}
.image-icon{
  height: 1rem;
  width: 1rem;
}
.ribbon {
  --t: 10%; /* the top offset */
  --f :10px
  /* position: absolute; */
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 2%;
  background: var(--color-secondary-dark);
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}
.num-data {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-subtitle);
  color: var(--color-primary-shipment-font);
  /* text-align: center; */
}
.num-data-light {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 400;
  color: var(--color-secondary-dark);
}
/* --Routing-- */
.locate-div-dashboard {
  height: 100%;
  width: 100%;
}

.locate-div-dashboard .gmnoprint {
  display: none;
}

.locate-div-dashboard .scdc {
  position: absolute;
  width: 13% !important;
  height: 40% !important;
  overflow-y: auto;
}

.locate-div-dashboard .gm-control-active.gm-fullscreen-control {
  display: none;
}
.css-1m9128y{
  margin: 0 !important;
}

.MuiGrid-root.MuiGrid-item.css-vizenz-MuiGrid-root > div:first-child > div:first-child{
  /* border-radius: 1rem; */
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.dashboard-container{
  /* background-color: black; */
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  position:relative;
}
.updateIcon{
  position: absolute;
  right: 0px;
  bottom: 30vh;
}
.finances-favorite-container{
  /* flex: 0.6 1; */
  flex: 0.5 1;
    height: 100%;
    /* background-color: bisque; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    /* align-items: center; */
    gap: 20px;
    /* flex-wrap: wrap;*/
}

.finances-rms-container{
  flex: 0.2;
  width: 100%;
  /* background-color: #236c9c; */
  display: flex;
  gap: 20px;
  cursor: default;
  /* height: 100%;*/
}

.finances-container{
  /* flex: 0.5 1; */
    /* height: 90%; */
    width: 100%;
    background-color: var(--color-white);
    /* margin: 15px 7.5px 15px 15px; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 12px;
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.finance-heading, .rms-heading{
  flex: 0.1;
  height: 100%;
  width: 100%;
  font-size: var(--fs-subtitle);
  padding-bottom: 1rem;
  font-weight: 500;
  color: var(--color-miscellaneous-2);
  font-family:var(--family-label);
}
.outstanding-due-container {
  flex: 0.9;
  height: 100%;
  width: 100%;
  /* background-color: gray; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 1rem;
}
.outstanding-container, .due-amount-container{
  flex: 0.5;
  background-color: white;
  height: 14rem;
  width: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* justify-content: fl */
  align-items: flex-start;
  padding: 5px;
}

.heading-outstanding, .heading-due-amount{
  flex: 0.1;
  /* height: 100%; */
  font-size: var(--fs-mid);
  padding-bottom: 1rem;
  width: 100%;
  font-weight: 500;
  color: var(--color-secondary-font);
  font-family: var(--family-label)
}

.table-head{
  background-color: gainsboro;
  border-radius: 5px;
  font-size: var(--fs-small);
  align-items: center;
  position: sticky;
  font-family: var(--family-title);
}

.table-data{
  font-size: var(--fs-small);
  
}
.testNew :hover{
  background-color: #e6f7ff;
}
.shipment-head{
  cursor: pointer;
  color: var(--color-primary-font);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 5rem;
  font-family: var(--family-title);
}
.shipment-head-list {
  font-family: var(--family-label);
  color: var(--color-secondary-font);
}
.shipment-head-dta{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 5rem;
  color: var(--color-secondary-font);

  font-family: var(--family-label);
}
.font-styling{
  font-family: var(--family-label);
  color: var(--color-secondary-font);
}
.data-outstanding, .data-due-amount{
  flex: 0.9;
  width: 100%;
  /* background-color: pink; */
  overflow: scroll;
}
.data-outstanding > table tr th, 
.data-outstanding > table tr td, .data-due-amount > table tr th, 
.data-due-amount > table tr td{
  padding: 4px !important;
  font-size: var(--fs-small);
  /* width: 20px; */
}


.rms-container{
  flex: 0.5 1;
    /* height: 90%; */
    width: 100%;
    background-color: var(--color-white);
    padding: 10px;
    /* margin: 15px 15px 15px 7.5px; */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 12px;
    padding: 12px 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.rms-carousel{
  flex: 0.8;
  height: 100%;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* background-color: gray; */
}
.rms-text{
  flex: 0.1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.favorite-container{
  flex: 0.8;
  width: 100%;
  height: 30vh;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
  cursor: default;
}
.favorite-container-check{
  flex: 1;
  width: 100%;
  height: 30vh;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
}
.inside-favorite-container{
  /* height: 100%;  */
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* padding: 12px 24px; */
  gap: 10px;
}

.favorite-orders-container, .favorite-shipment-container{
  /* flex: 0.5 1; */
  height: 100%;
  width: 100%;
  /* background-color: burlywood; */
  border-radius: 0px 12px 12px 0px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 5px;
  padding: 10px 0px;
} 

.heading-filterbutton-container{
  flex: 0.05;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.favorite-orders-filter-button, .favorite-shipment-filter-button{
  border: 1px solid #236C9C;
  border-radius: 26px;
  background-color: var(--color-white);
  font-size: var(--fs-small);
  height: 25px;
  width: 90px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.heading-orders, .heading-shipment{

  font-size: var(--fs-subtitle);
  padding-bottom: 5px;
  font-weight: 500;
  color: var(--color-secondary-font);
  font-family: var(--family-label);
  padding: 0px 10px;

}
.orders-data, .shipment-data{
  flex: 0.95;
  width: 100%;
  max-height: 100vh;
  /* max-height: 31rem; */
  /* background-color: beige; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.orders-data{
  padding-left: 10px;
}
.shipment-data{
  padding-right: 10px;
  padding-left: 10px;
}

.data-card{
  width: 98%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* background-color:beige; */
  margin-bottom: 15px;
  padding: 10px;
  
}

.card-body-container{
display: flex;
justify-content: center;
/* align-items: first baseline; */
align-items:center;
width: 100%;
/* background-color: pink; */
padding: 10px;
}

.status-shipmentnumber-container{
  flex: 0.4;
  display: flex;
  /* justify-content: center;
  align-items: flex-start; */
  flex-direction: column;
  /* padding: 5px; */
  /* background-color: gray; */
  gap: 7px;
}
.status-container{
  width: fit-content;
  background-color: #236C9C;
  color: var(--color-white);
  padding: 0 10px 0 10px;
  font-family: var(--family-title);
  font-size: var(--fs-mid);
  cursor: default;
}
.shipmentID-container{
  color: var(--color-primary-font);
  padding-top: 15px;
  font-size: var(--fs-mid);
  font-family: var(--family-title);
  font-weight: 500;
  cursor: pointer;
}
.logistics-container{
  flex: 0.6;
  display: flex;
  /* align-items: first baseline; */
  flex-direction: column;
  /* background-color: gray; */
  margin-top: -1px;
  gap: 20px;
  padding-left: 20px;
  border-left: 1px solid #E7E7F0;
}
.status-img-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
  /* width: 60%; */
  width: 72%;
  position: relative;
}

.origin-etd-container, .destination-eta-container{
  display: flex;
  justify-content: space-between;
  /* background-color: green; */
  width: 100%;
}
.check-font{
 margin: 0;
    color: var(--color-tertiary-font);
    font-size: var(--fs-small);
    font-weight: 400;
    font-family: var(--family-label);
 
}
.check-font-list{
  word-break: break-word;
  margin: 0;
  color: var(--color-secondary-font);
  font-size: var(--fs-mid);
  font-weight: 400;
  font-family: var(--family-label);
}
.map-etd-eta-container{
  /* flex: 0.4 1; */
  flex: 0.5 1;
  height: 100%;
  /* background-color: blanchedalmond; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  gap: 20px;
  cursor: default;
}
.map-upcoming-container{
  flex: 0.65 1;
  width: 100%;
  /* background-color: blue; */
  height: 20vh;
  
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* gap: 20px;*/
}

.inside-container{
  /* height: 100%; */
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* padding: 12px 24px; */
  gap: 10px;
}

.map-container{
  flex: 0.65;
  height: 100%;
  /* background-color:burlywood; */
  border-radius: 12px 0 0 12px;
}

.map-container > div:first-child{
  height: 98%;
  width: 100%;
}

.upcoming-container{
  flex: 0.35;
  height: 100%;
  /* background-color: burlywood; */
  border-radius: 0px 12px 12px 0px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 5px;
  padding: 5px;
}
.upcoming-heading{
  flex: 0.05;
  width: 100%;
  padding-left: 10px;
  /* background-color: beige; */
  font-size: var(--fs-subtitle);
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: 500;
  color: var(--color-miscellaneous-2);
  font-family: var(--family-label);

}
.upcoming-buttons{
  flex: 0.1;
  /* width: 93%; */
  /* padding-left: 10px; */
  margin-left: 5px;
  /* background-color: beige; */
  display: flex;
  align-items: center;
  justify-content: start;
}

.upcoming-buttons .shipment-button {
  width: 95%;
  border: none;
  background-color: #236C9C;
  font-size: var(--fs-subtitle);
  color: var(--color-white);
  padding: 5px 15px;
  border-radius: 12px;
  font-family: var(--family-label);
  font-weight: 400;
  cursor: default;
}

.upcoming-buttons .orders-button{
  border: 1px solid var(--color-secondary-dark);
  /* background-color: #236C9C; */
  font-size: var(--fs-subtitle);
  color: var(--color-secondary-dark);
  padding: 5px 20px;
  border-radius: 22.2px;
  font-family: var(--family-head);
  font-weight: 400;
  background-color: var(--color-white);

}
.upcoming-data{
  flex: 0.85;
  /* width: 100%; */
  max-height: 31rem;
  /* background-color: beige; */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.upcoming-data .infinite-scroll-component__outerdiv{
  width: 100%;
}
.upcoming-data-card{
  width: 95%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* background-color:beige; */
  margin-bottom: 15px;
  margin-left: 5px;
  padding: 15px;
}

.upcoming-card-body-container{
  display: flex;
  flex-direction: column;
  padding: 0;
}

.shipment-image-container{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #E7E7F0;
}

.shipment-number-container{
  font-size: var(--fs-mid);
  font-weight: 500;
  cursor: pointer;
  color: var(--color-secondary-dark) !important;
  white-space: nowrap;
  font-family: var(--family-title);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
}

.upcoming-destination-eta-container{
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  gap: 5px;
}







.etd-eta-container{
  flex: 0.35 1;
  width: 100%;
  /* background-color: #236c9c; */
  display: flex;
  /* align-items: first baseline; */
  /* justify-content: center;*/
}

.inside-container-eta-etd{
  /* height: 95.5%; */
  width: 100%;
  background-color:var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  /* padding: 12px 24px; */
  gap: 10px;
  /* padding: 10px; */
}

.eta-expectation-container{
  flex: 0.5;
  height: 100%;
  /* background-color: burlywood; */
  border-radius: 12px 0 0 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
}

.heading-eta-expectation, .heading-etd-expectation{
  flex: 0.1;
  /* background-color: pink; */
  font-size: var(--fs-subtitle);
  padding-bottom: 5px;
  font-weight: 500;
  color: var(--color-secondary-font);
  font-family: var(--family-label)

}

.data-eta-expectation, .data-etd-expectation{
  flex: 0.9;
  /* background-color:antiquewhite; */
  overflow: auto;
  max-height: 15rem;
}

.etd-expectation-container{
  flex: 0.5;
  height: 100%;
  /* background-color: burlywood; */
  border-radius: 12px 0 0 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 20rem;
}

.individual-eta-expectation{
  display: flex;
  justify-content: space-between;
  padding: 4px 7px;
  border-bottom: 1px solid #E7E7F0;
  width: 97%;
}
.eta-expectation-header, .etd-expectation-header{
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  margin-right: 5px;
}

.eta-expectation-shipment-data, .etd-expectation-shipment-data{
  margin-left: 2px;
  cursor: pointer; 
  color: var(--color-secondary-dark)
}

.old-eta-heading{
  padding-right: 20px;
  
}

/* .new-eta-heading {
  padding-right: 6px;
}


.new-etd-heading{
  padding-right: 5px;
} */

/* vertical ScrollBar width */
/* ::-webkit-scrollbar {
  width: 5px;
} */
/* horizontal Scroll bar */
/* ::-webkit-scrollbar:horizontal {
  height: 5px !important;
  background: var(--color-primary);
  border-radius: 10px;
} */
/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background:  #d0deef;

} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background:  #6190CB;
  opacity: 0.3;
} */

@media only screen  and (min-width: 1600px) {
  .favorite-orders-container, .favorite-shipment-container {
    /* height: 50.75vh; */
    /* background-color: black; */
  }
}
@media only screen  and (min-width: 1537px) and (max-width: 1599px) {
  .favorite-orders-container, .favorite-shipment-container {
    /* height: 53vh; */
    /* background-color: black; */
  }
  .upcoming-data{
    max-height: 38rem;
  }
  .eta-expectation-container, .etd-expectation-container 
    {
    max-height: 25rem;
  }

  .data-eta-expectation, .data-etd-expectation{
    max-height: 21rem;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1536px){
  .favorite-orders-container, .favorite-shipment-container {
    /* height: 53vh; */
  }
  .upcoming-data{
    max-height: 18.35rem;
  }
  .old-eta-data{
    padding-left: 40px;
  }

  .old-eta-heading{
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1312px){
  .finances-favorite-container,.map-etd-eta-container{
    flex: 1;
  }
}

@media only screen and (max-width: 900px){
  /* .dashboard-container{
    height: fit-content;
  } */
  
  .finances-container{
    padding: 12px 13px;
    
  }

  .rms-container{
    padding: 12px 13px;

  }
  .outstanding-due-container{
    gap: 10px;
  }

  .etd-eta-container{
    max-height: 20rem;
  }
  .data-eta-expectation, .data-etd-expectation{
    max-height: 16rem;
  }
  .map-etd-eta-container{
    margin-top: 20px;
  }
}


@media screen and (max-width: 424px){
  .status-shipmentnumber-container{
    flex: 0.5;
  }
  .map-etd-eta-container{
    height:unset;
  }
  .finances-favorite-container{
    height: unset;
  }
  .finances-rms-container{
    display: block;
  }
  .finances-container{
    margin-bottom: 20px;
  }
  .favorite-orders-container{
    margin-bottom: 20px;
  }
  .favorite-container{
    display: block;
  }
  .inside-container-eta-etd{
    display: block;
    height: fit-content;
  }
  .etd-eta-container{
    max-height: fit-content;
  }
  .inside-container{
    flex-direction: column;
    height: 60rem;
  }
  .upcoming-container{
    width: 100%;
  }
  .map-container{
    width: 100%;
    height: 40rem;
  }
}




