/* search bar  */
.headersearch.searchShipmentDiv {
  flex-grow: unset;
}

.headersearch.searchShipmentDiv img {
  font-size: 3rem;
  position: absolute;
  left: 0.5rem;
  cursor: pointer;
}

.headersearch.searchShipmentDiv input.form-control {
  padding: 0.65rem 2rem 0.65rem 2.5rem;
  font-size: 1rem;
  border-radius: 2rem;
  border: none;
}

.headersearch.searchShipmentDiv svg.searchClearIcon {
  font-size: 2.5rem;
  height: 25px;
  width: 25px;
}

.headersearch {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  flex-grow: 2;
}

.headersearch svg.searchIcon {
  left: 0.5rem;
}

/* --Media query-- */
@media screen and (max-width: 479px) {
  .form-control {
    height: 2.5rem;
    font-size: 12px !important;
  }

  .searchIcon {
    height: 1rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .form-control {
    height: 2.5rem;
    font-size: 12px !important;
  }
}
