/* Table row div */
.tableRow-div {
  background-color: var(--color-table-header);
  padding: 0.5rem;
  margin-bottom: 1.2rem;
}
.color-style-user:hover {
  background-color: var(--color-primary) !important;
}
.color-style-user {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background-color: var(--color-primary) !important;
}

.tableBody-div {
  background-color: var(--color-white);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: var(--family-label);
  color: var(--color-secondary-font);
}

.wordWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-div {
  cursor: pointer;
  border-radius: 20px;
  padding: 0.5rem;
  background-color: var(--color-white);
}

.list-style li {
  color: var(--color-primary-dark);
  font-size: 1.5rem;
}

.grant-color {
  background-color: var(--color-ternary-light);
  height: 2rem;
  border-radius: 5px;
  color: var(--color-ternary);
  padding: 3px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.admin-color {
  background-color: var(--color-primary-light);
  height: 2rem;
  border-radius: 5px;
  color: var(--color-secondary-dark);
  padding: 3px;
  /* min-width: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.user-section {
  overflow-y: auto;
  height: 83vh;
}

.userCard {
  display: none;
}

.cardTop {
  background-color: var(--color-white);
  border-radius: 2rem;
  padding: 1rem 1rem;
  margin-top: 1rem;
}

.usercard_btn {
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding: 0.1rem 0;
  border-radius: 1rem;
}
.usercol_data,
.usercol_data_orgnisation {
  position: relative !important;
}
.usercol_data > span {
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  left: 13px !important;
  overflow: hidden !important;
  position: absolute !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 102px;
}
.usercol_data_orgnisation > span {
  left: 13px !important;
  overflow: hidden !important;
  position: absolute !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 125px;
}
/* ---UserEdit modal--- */
.title-user {
  font-weight: 700 !important;
}
.Dialog-section-user > div {
  background-color: rgb(0 0 0 / 0%);
}
.Dialog-section-user .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  padding: 12px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.disable {
  background-color: #dddddd !important;
}
.available {
  color: var(--color-secondary-dark) !important;
}
@media screen and (max-width: 992px) {
  .tablediv {
    display: none;
  }

  .userCard {
    display: block;
  }
}
.removePadding.css-ypiqx9-MuiDialogContent-root {
  padding: 0px !important;
}

/* to blink the information on top  */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink-text {
  animation: blink 2s infinite; /* Adjust the duration as needed */
}
