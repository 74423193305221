.common-p {
  margin: 0;
}
.booking-dashboard {
  height: 100%;
  width: 100%;
  align-items: flex-start;
}

.booking-heading {
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-subhead);
  color: var(--color-primary-font);
}

.booking-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.booking-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 40px;
  height: 40px;
  margin: 0 5px 0 5px;
  border-radius: 50%;
  background-color: var(--color-white);
}

.booking-search {
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 12px;
  left: 15px;
}

.booking-search > input {
  width: 100%;
  border: none;
  border-radius: 10px;
  height: 40px;
  margin: 0 5px 0 5px;
  padding-left: 35px;
}

.booking-add {
  display: flex;
  justify-content: center;
  /* margin: 0 5px 0 5px !important; */
}

.booking-table > table {
  height: 30rem;
}

.user-type {
  overflow: auto;
  /* display: flex; */
  background-color: white;
  height: 100%;
  max-height: 100%;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}

.org-dest {
  width: 50%;
  /* background-color: burlywood; */
  padding: 0px 0px 0px 10px;
}
.org-dest > p {
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  color: #236c9c;
}

.additional-services p {
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  color: #236c9c;
}

.buyer-supplier {
  width: 50%;
  padding-right: 10px;
}

.cargo-details {
  background-color: white;
  height: 100%;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.doc-upload {
  background-color: white;
  height: 100%;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}

.order-details {
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.details-container {
  display: flex;
  flex-basis: 100%;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}
.details-left {
  width: 100%;
  padding-top: 1rem;
}
.details-right {
  width: 100%;
  padding-top: 1rem;
}
.details p {
  color: var(--color-primary-font);
}

.table-container-div {
  padding-right: 5px;
  /* overflow-x: scroll; */
  overflow-y: scroll;
  max-height: 19rem;
}

.table-head.air {
  min-width: 40rem;
}
.wordEllips {
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
  max-width: 100%; /* Ensure the div doesn't grow beyond its bounds */
  /* You might need to adjust the width according to your layout */
}

.details-side-parent {
  width: 100%;
  min-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* overflow-y: scroll; */
}
.header-p {
  color: var(--color-primary-font);
}
.cargo-common {
  padding: 1rem;
  height: 29rem;
  /* overflow: auto; */
}
.cargo-common p {
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  color: #236c9c;
}
.order-details p {
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  color: #236c9c;
}
.cargo-details-bottom {
  display: flex;
  column-gap: 1rem;
  /* flex-wrap: wrap; */
}
.buyer-supplier p {
  /* display: inline; */
  margin: 0;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  color: #236c9c;
}

.buyer-supplier p:hover {
  background-color: none !important;
}

.edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-layout {
  display: flex;
  gap: 1rem;
  /* width: 100%; */
  justify-content: end;
}
.view-booking-parent {
  background-color: var(--color-white);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-booking-header {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 12px;
  padding: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  margin-top: 1rem;
}

.accordion {
  box-shadow: none !important;
}
.view-booking-details-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 1rem;
  width: 33.33%;
  padding-top: 1rem;
}
.view-booking-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 768px) {
  .view-booking-details-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 1rem;
    width: 50%;
    padding-top: 1rem;
  }
}
@media (max-width: 480px) {
  .view-booking-details-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 1rem;
    width: 100%;
    padding-top: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .details-container{
    flex-direction: column;
  }
}
