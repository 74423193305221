.parent-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.child-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.child-container .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 0px !important;
}
