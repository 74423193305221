html,
body,
#root {
  height: 100%;
  width: 100%;
}
/* vertical ScrollBar width */
::-webkit-scrollbar {
  width: 5px;
}
/* horizontal Scroll bar */
::-webkit-scrollbar:horizontal {
  height: 5px !important;
  background: var(--color-primary);
  border-radius: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-light);
}

#root > div:nth-child(2) {
  height: 100%;
  background-color: #f5f5f5;
}
/* ------------------ */
.index-card::-webkit-scrollbar {
  width: 5px;
}
.index-card::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.index-card::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.index-card::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* --------------- */
.outlet-main::-webkit-scrollbar {
  width: 5px;
}
.outlet-main::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.outlet-main::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.outlet-main::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* =-------------- */
.tableWrap::-webkit-scrollbar {
  width: 5px;
}
.tableWrap::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.tableWrap::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.tableWrap::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* ------------- */
.overflow_detail::-webkit-scrollbar {
  width: 5px;
}
.overflow_detail::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.overflow_detail::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.overflow_detail::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* ------------- */
.upcoming-data::-webkit-scrollbar {
  width: 5px;
}
.upcoming-data::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.upcoming-data::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.upcoming-data::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* ------------- */
.data-eta-expectation::-webkit-scrollbar {
  width: 5px;
}
.data-eta-expectation::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.data-eta-expectation::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.data-eta-expectation::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
/* ------------- */
.data-etd-expectation::-webkit-scrollbar {
  width: 5px;
}
.data-etd-expectation::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */
.data-etd-expectation::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}

/* Handle on hover */
.data-etd-expectation::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-light);
}
:root {
  /* --Font-color-- */
  --color-primary: #0a2d44;
  --color-primary-dark: #0065e3;
  --color-primary-light: #cfdbeb;
  --color-secondary: #009ef7;
  --color-secondary-dark: #236c9c;
  --color-secondary-light: #6191cb;
  --color-ternary: #0b8d00;
  --color-ternary-dark: #282828;
  --color-ternary-light: #aaf3a4;
  --color-miscellaneous-1: #d0def0;
  --color-miscellaneous-2: #5b5675;
  --color-miscellaneous-3: #e7e7f0;
  --color-miscellaneous-4: #7c7c7c;
  --color-miscellaneous-5: #eeeeee;
  --color-miscellaneous-6: #f5f5f5;
  --color-miscellaneous-7: #ff0000;
  --color-miscellaneous-8: #a3a2ac;
  --color-miscellaneous-9: #dcdbe5;
  --color-white: #fff;
  --color-black: #000;
  --color-orange : #fd6100;
  --color-table-header: #b0cbec;  
  --color-primary-button: #0a2d44;
  --color-primary-font: #0a2d44;
  --color-secondary-font: #5b5675;
  --color-tertiary-font:#7c7c7c;
  --color-primary-shipment-font:#236c9c;
  --color-primary-button: #236c9c;
  --color-secondary-button:#b0cbec;
  
  /* font family */

  --family-label: "Poppins";
  --family-title: "Open Sans";
  --family-head: "Roboto";
  --family-header: "Inter";
  --family-style: "normal";

  /* --font-size--*/
  --fs-small: 12px;
  --fs-new: 13px;
  --fs-mid: 14px;
  --fs-lg: 16px;
  --fs-title: 16.2624px;
  --fs-subtitle: 18px;
  --fs-head: 20px;
  --fs-subhead: 22px;
  --fs-label: 24px;
  --fs-sublabel: 28px;
  --fs-md: 38px;
}

@media screen and (max-width: 479px) {
  :root {
    --fs-small: 8px;
    --fs-new: 5px;
    --fs-mid: 10px;
    --fs-lg: 12px;
    --fs-title: 8.2624px;
    --fs-subtitle: 12px;
    /* --fs-head: 12px; */
    --fs-subhead: 14px;
    --fs-label: 16px;
    --fs-sublabel: 20px;
    --fs-md: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  :root {
    --fs-small: 7px;
    --fs-new: 8px;
    --fs-mid: 9px;
    --fs-lg: 11px;
    --fs-title: 11.2624px;
    --fs-subtitle: 13px;
    --fs-head: 15px;
    --fs-subhead: 17px;
    --fs-label: 19px;
    --fs-sublabel: 23px;
    --fs-md: 33px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  /* --font-size--*/
  :root {
    --fs-small: 10px;
    --fs-new: 11px;
    --fs-mid: 12px;
    --fs-lg: 14px;
    --fs-title: 14.2624px;
    --fs-subtitle: 16px;
    --fs-head: 18px;
    --fs-subhead: 20px;
    --fs-label: 18px;
    --fs-sublabel: 26px;
    --fs-md: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  :root {
    --fs-label: 18px;
  }
}

@media only screen and (min-width: 1400px) {
  :root {
    --fs-label: 20px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  :root {
    --fs-small: 10px;
    --fs-new: 11px;
    --fs-mid: 12px;
    --fs-lg: 14px;
    --fs-title: 14.2624px;
    --fs-subtitle: 16px;
    --fs-head: 18px;
    --fs-subhead: 20px;
    --fs-label: 20px;
    --fs-sublabel: 26px;
    --fs-md: 36px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1900px) {
}
