.overlayPadding {
  padding: 0% 3% 3% 3%;
}
.overCard {
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}
.milestonesCard{
  position: absolute;
  z-index:  1;
  background-color: var(--color-white);
  bottom: 4px;
  right: 4px;
  height: 65vh;
  @media screen and (max-width:480px){
    width: 100%;
    bottom: 1;
  right: 0;
  };
  @media screen and (min-width:480px) and (max-width:768px){
    width: 70%;
  }
  width: 30%;
}

.removeRed{
  color: rgba(0, 0, 0, 0.6);
}

.validation-error {
  color: #d32f2f;
  font-family: var(--family-label);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  /* text-align: left; */
  margin-top: 4px;
  /* margin-right: 14px; */
  margin-bottom: 0;
  /* margin-left: 14px; */
}
.removeRed div > fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important ; 
}
/* .removeRed .css-1baqzcl.Mui-error{
  color : rgba(0, 0, 0, 0.23)!important;
}
.removeRed .css-dnbppq.Mui-error{
  color : rgba(0, 0, 0, 0.23)!important;
}
.removeRed .css-na1uuw.Mui-error .MuiOutlinedInput-notchedOutline{
  color : rgba(0, 0, 0, 0.23)!important;
} */