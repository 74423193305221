.layout-top {
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
}
.layout-cover {
  height: 93%;
  display: flex;
}
.layout-main {
  width: 80%;
  /* max-height: 49rem; */
  /* background-color: orange; */
  flex: 1;
}
.layout-padding {
  padding: 0.5rem;
}
.layout-sidebar {
  flex-shrink: 0;
  height: 100%;
  padding: 0.5rem;
}
/* .org-dest{
  
} */
/* .buyer-supplier{
  
} */
.input-gap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.textVisiblity{
  display: block;
}
.sidebar {
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}
.sidebar-menu {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-primary);
  opacity: 0.5;
}
.sidebar-menu-selected {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-primary);
}
/* Upload document started*/
.upload-layout {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.upload-button {
  /* position: sticky; */
  position: absolute;
  min-width: 4rem;
  bottom: 1%;
  left: 1%;
}
.upload-drawer-visible {
  background-color: gainsboro;
  position: absolute;
  bottom: 10%;
  width: 100%;
  left: 0;
}
.upload-drawer-hidden {
  display: none;
}
.upload-drawer-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.2rem;
}
.upload-drawer-head > h6 {
  color: var(--color-primary);
}
.upload-table {
  /* display: grid; */
  /* overflow: auto; */
  width: 100%;
}
.upload-table-body {
  max-height: 20rem;
  overflow: auto;
}
.btn-submit {
  height: 2.5rem;
  width: max-content;
  margin-left: 5px !important;
  margin-top: 10px !important ;
  font-size: var(--fs-mid) !important;
  background-color: var(--color-primary-button) !important;
  color: var(--color-white) !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.btn-submit:hover {
  background-color: var(--color-secondary-button) !important;
  color: var(--color-black) !important;
}
.userTypesContainer {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .userTypesContainer {
    display: flex;
    flex-direction: column;
  }
  .buyer-supplier , .org-dest {
    width: 100%;
  }
  .textVisiblity {
    display: none;
  }
  .layout-sidebar{
    width: 15vw;
  }
}
/* @media screen and (max-width: 850px) {
  .layout-main {
    width: 100%;
  }
  .layout-cover {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

} */
.wordEllips {
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis to overflow text */
  max-width: 90%; /* Ensure the div doesn't grow beyond its bounds */
  /* You might need to adjust the width according to your layout */
}
.tableContainer{
  width: 100%;
  overflow-x: scroll;
}
/* @media screen and (max-width: 1024PX) {
  .upload-table {
    display: grid;
    overflow: auto;
    width: 100%;
  }
} */