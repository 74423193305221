.docuploadfile {
  background-color: #f5f5f5;
  border: 3px dashed #d0def0;
  border-radius: 10px;
}
.docuploadtext {
  font-family: "Poppins";
  color:var(--color-primary-font);
}
.doctitle {
  font-family: "Poppins";
  color:var(--color-primary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.buttonupload {
  background-color: var(--color-primary);
  border: 1px solid #868686;
  border-radius: 10px;
}
