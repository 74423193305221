.aging-statment {
  color: var(--color-black);
  border-right: 1px solid var(--color-primary-light);
  overflow-y: auto;
  height: 6rem;
  background-color: var(--color-white);
  border-radius: 1.5rem;
}

.card-main-div {
  flex-direction: row;
  width: 11rem;
  border-radius: 2rem;
}

.search-parent-div {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* Table row div */
.tableRow-div {
  background-color: var(--color-table-header);
  padding: 0.5rem;
  margin-bottom: 1.2rem;
}

.tableBody-div {
  background-color: var(--color-white);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.wordWrap {
  word-wrap: break-word;
}

@media only screen and (max-width: 764px) {
  .statement-header {
    flex-direction: column-reverse;
  }

  .card-top {
    margin: 0 !important;
  }

  .search-parent-div {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/*  Mobile Responsive */
@media only screen and (min-width: 0px) and (max-width: 992px) {
  .modal-dialog {
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
    width: 100%;
  }

  .sip-card ul li {
    font-size: 14px !important;
  }

  .sip-card ul li:nth-child(2) {
    font-weight: 500 !important;
  }

  .css-6h2bki-MuiButtonBase-root-MuiButton-root {
    min-width: auto !important;
  }

  .tableBody-div {
    display: none;
  }

  .sip-card {
    display: block !important;
  }

  .sip-desk-tab {
    display: none;
  }

  .btn-out-section {
    font-size: 16px;
    min-width: 7rem;
  }
  .btn-dash > .css-ahjo93-MuiButtonBase-root-MuiButton-root {
    min-width: 11rem;
  }
  .top-sec {
    padding: 15px 12px;
    margin-bottom: 15px !important;
  }

  .card {
    border: 0px solid rgba(0, 0, 0, 0.125) !important;
    margin-bottom: 10px;
    border-radius: 32px;
  }

  .border-bottom-sip {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
  }

  .border-left-sip {
    border-left: 1px solid #e5e5e5;
  }

  .border-top-sip {
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }

  .userCard {
    margin-left: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .user-card {
    border-radius: 12px;
  }

  .modal-content {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }

  .modal-body p {
    font-size: 13px;
    line-height: 10px;
    word-wrap: break-word;
  }

  .locicon {
    border: 1px solid;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-radius: 8px;
  }
  .modal-dialog {
    max-width: 100%;
    margin-bottom: 0;
  }
}

.sip-card {
  display: none;
}
.heading {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-filter {
  padding: 5px 5px;
  color: var(--color-secondary-light);
  font-size: var(--fs-title);
  border: 1px solid var(--color-secondary-light);
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}
.color-style {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  background-color: var(--color-primary) !important
  ;
}
.color-primary {
  color: var(--color-secondary-dark);
}
.color-white {
  color: var(--color-white);
}
