.builtdate{
    font-size: xx-large;
    font-weight: 500;
}
.heading{
    margin-left: 19px;
    font-weight: 500;
    font-size: larger;
    text-decoration: underline;
}
.innerContent{
    margin-left: 60px;
}
.noDataFound{
    height: 100%;
    width: 100%;
}
.center{
    min-width: 20vw;
    min-height: 40vh;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderCss{
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}