.Header-Main,
.Header-Main > header.MuiPaper-root.MuiPaper-elevation {
  height: 4.5rem;
  background-color: var(--color-white);
}

/* .Header-Main header.MuiPaper-root.MuiPaper-elevation>div.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root {
  max-width: 100%;
} */

.outlet-main {
  padding: 1rem 1rem 1rem 1rem;
  height: calc(100% - 4.5rem);
  overflow-y: auto;
}

.select-shipment-details {
  color: var(--color-primary);
  font-size: var(--fs-subhead);
}

.layout-head {
  margin: 0;
  /* padding-right: 210px; */
  font-family: var(--family-label);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-subhead);
  color: var(--color-primary);
}

.layout-button {
  border: 0.8px solid var(--color-primary);
  border-radius: 20px;
  font-family: var(--family-head);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-title);
  color: var(--color-primary);
  background-color: transparent;
  /* width: 20%; */
}

.layout-icon-div {
  background-color: var(--color-white);
  border-radius: 20px;
  padding: 0.5rem;
}

.icons-shipment-page {
  background-color: var(--color-primary);
  /* border-radius: 50%; */
  height: var(--fs-md);
  width: var(--fs-md);
}

.border-radius-right {
  border-radius: 50%;
  /* border-bottom-right-radius: 50%; */
}

.border-radius-left {
  border-radius: 50%;
  /* border-bottom-left-radius: 50%; */
}

.icons-shipment-page-inactive {
  background-color: var(--color-white);
  border-radius: 50%;
  height: var(--fs-md);
  width: var(--fs-md);
}

.back-dark {
  background-color: var(--color-primary);
}

.layout-icon {
  padding: 0.3rem;
  cursor: pointer;
}

.layout-head-section {
  background-color: var(--color-white);
  border-radius: 3rem;
  /* min-width: 2rem; */
  padding: 0 !important;
  min-width: 6rem;
}

/* --LayoutDetails-- */
.detail-header {
  background-color: var(--color-white);
  /* height: 100%; */
}

.overflow_detail {
  /* change for routing */
  overflow-y: auto;
}

.details-head-div {
  border-bottom: 1px solid var(--color-miscellaneous-3);
}

.detail-text-head {
  padding-right: 5px;
  font-family: var(--family-title);
  font-style: var(--family-style);
  font-weight: 700;
  font-size: var(--fs-head);
  color: var(--color-primary);
}

.button-chat {
  border-radius: 35px;
  background-color: transparent;
  border: 0.903468px solid var(--color-primary);
}

.text-chat {
  font-family: var(--family-title);
  font-style: var(--family-head);
  font-weight: 700;
  font-size: var(--fs-title);
}

.shipment-top-div div.MuiInputBase-colorPrimary {
  border-radius: 2rem;
}

.shipment-top-div {
  margin-bottom: 0.5rem;
}

.small-screen-layout-head {
  display: none;
}

.select-shipment-div {
  background-color: var(--color-white);
}

.layout-details-div {
  /* height: calc(100% - 6vh); */
  height: 73.5vh;
}

/* .layout-details-div>div {
  height: calc(100% - 3vh);
} */
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
  /* .select-shipment-div>img {
    height: 23rem !important;
    width: 36rem !important;
  } */
  /* .layout-head {
    padding-right: 215px;
  } */
}

@media only screen and (max-width: 576px) {
  .layout-head-section {
    min-width: 4.5rem;
    height: 2rem;
  }

  .small-screen-layout-head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: var(--color-primary);
    padding-bottom: 0.5rem;
    font-family: var(--family-label);
    font-style: var(--family-style);
    font-weight: 700;
    font-size: var(--fs-label);
  }

  .layout-select-box {
    padding: 0;
  }

  .layout-button {
    width: 100%;
  }
}
