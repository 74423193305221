body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Poppins", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.height-100 {
  height: 100%;
}
.cursorPointer {
  cursor: pointer;
}
.locationTracker {
  color: rgb(1, 124, 38);
  animation: blinking 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinker {
  animation: blink 1s;
  animation-iteration-count: infinite;
}

.css-1vrjf49-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #6e6e6e !important;
}
.captcha-container {
  display: flex;
  align-items: center;
}
.captcha-container-track {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}
.captcha-code-track {
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 12px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 5px;
}
.captcha-code {
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  letter-spacing: 3px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 5px;
}

.captcha-code::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Replace 'captcha-background.jpg' with the URL or path to your background image */
  /* background-image: url('C:\Users\Puneet\Documents\GitHub\HubPdtSFLINSIGHT-V2\public\assets\HeaderLogo.png'); */
  opacity: 0.8;
  filter: blur(3px);
}

.captcha-code span {
  display: inline-block;
  transform: skewX(-40deg) rotate(-20deg);
  margin-right: 4px;
  color: transparent;
  text-shadow: 0 0 1px var(--color-secondary-dark);
}

.refresh-button {
  background: none;
  border: none;
  color: #236c9c;
  cursor: pointer;
}
.pattern-dot {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='1.5' fill='black'/%3E%3C/svg%3E");
}
.pattern-dash {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Crect x='0' y='19' width='20' height='2' fill='black'/%3E%3C/svg%3E");
}
.pattern-cross-dots {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='5' cy='7' r='1' fill='black'/%3E%3Ccircle cx='15' cy='15' r='1' fill='black'/%3E%3C/svg%3E");
}
.pattern-cross {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cline x1='0' y1='10' x2='20' y2='10' stroke='black' stroke-width='1'/%3E%3Cline x1='0' y1='0' x2='0' y2='0' stroke='black' stroke-width='1'/%3E%3C/svg%3E");
}
.pattern-dot-cross {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='10' cy='10' r='2' fill='black'/%3E%3Cline x1='0' y1='10' x2='20' y2='10' stroke='black' stroke-width='2'/%3E%3Cline x1='10' y1='0' x2='10' y2='20' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
}
